import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { useUserStoreContext } from '@/store/user.v2.store';
import { Box, Button, CircularProgress, Divider, Fade, Modal, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';

const style = {
  position: 'absolute',
  top: '50%',
  right: '50%',
  transform: 'translate(50%,-50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
};

const UserModal = () => {
  const {
    modal: { body, openModal, onClose, onConfirm },
    updateSuspend,
  } = useUserStoreContext();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const onClickConfirm = useCallback(() => {
    if (body.id) {
      onConfirm(body.id, Boolean(body.checked));
    }
  }, [body.id, body.checked]);

  const loading = useMemo(() => {
    return updateSuspend.current.state === STATE_ENUM.LOADING;
  }, [updateSuspend.current.state]);

  return (
    <Modal
      id={`modal-user-page`}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      closeAfterTransition
    >
      <Fade in={openModal}>
        <Box sx={style} width={690}>
          <>
            <Typography variant="h4" sx={(theme) => ({ padding: `${theme.spacing(2)} ${theme.spacing(4)}` })}>
              {body.header}
            </Typography>
            <Divider orientation="horizontal" />
          </>
          <Box
            sx={(theme) => ({
              padding: theme.spacing(4),
            })}
          >
            <Typography variant="body1" sx={(theme) => ({ color: theme.palette.text.secondary, wordWrap: 'break-word' })}>
              {body.description}
            </Typography>
            <Typography variant="body1" sx={(theme) => ({ color: theme.palette.text.secondary, wordWrap: 'break-word', mt: 2 })}>
              {body.subDescription}
            </Typography>
            <Box sx={{ mt: 5 }}>
              <Button color="error" disabled={loading} variant="contained" sx={{ mt: 2 }} onClick={onClickConfirm}>
                {loading && <CircularProgress color="inherit" size={20} sx={{ mr: 1 }} />}
                {body.buttonText}
              </Button>
              <Button color="error" variant="outlined" sx={{ mt: 2, ml: 2 }} onClick={handleClose}>
                CANCEL
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default UserModal;

/* eslint-disable */
import { useRestaurantBookingContext, useRestaurantContext } from '@/services/index';
import { styles } from '@/src/styles/theme';
import { RestaurantSchema } from '@/src/types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { common } from '@mui/material/colors';
import React, { useEffect } from 'react';

interface SelectRestaurantFallbackMealProps {
  restaurantId: string;
  onChange: (v: string) => void;
  disabled?: boolean;
  checkLoad: boolean;
  edit?: boolean;
}

export function SelectRestaurantFallbackMeal(Props: SelectRestaurantFallbackMealProps) {
  const { restaurantListFallBackMeal } = useRestaurantContext();
  const { restaurantBookingList } = useRestaurantBookingContext();
  const { restaurantId, onChange = () => {}, disabled = false, checkLoad, edit } = Props;
  const [restaurant, setRestaurant] = React.useState<string>(restaurantId || '');

  const handleChange = (event: SelectChangeEvent) => {
    setRestaurant(event.target.value as string);
    onChange(event.target.value as string);
  };

  useEffect(() => {
    setRestaurant(restaurantId ?? '');
  }, [restaurantId]);

  // const uniqueRestaurantBookingList = [...new Map(restaurantBookingList.data.map((item) => [item.restaurantId, item])).values()];
  let restaurantListForDropdown: RestaurantSchema[] = restaurantListFallBackMeal?.data;
  // NOTE: find the restaurant in the list, the deleted restaurant will not found here
  const findRestaurantInList = restaurantListFallBackMeal?.data?.find((item) => item.id === restaurantId);
  // NOTE: find the deleted restaurant in booking list
  const findRestaurantInBookingList = restaurantBookingList?.data?.find((item) => item.restaurantId === restaurantId);
  // NOTE: if don't found the deleted restaurant in list and found in booking list, then combine founded restaurant with restaurant list
  if (!findRestaurantInList && findRestaurantInBookingList) {
    restaurantListForDropdown = [
      ...restaurantListForDropdown,
      {
        lastOrder: '',
        id: findRestaurantInBookingList.restaurantId,
        thName: findRestaurantInBookingList.thName,
        enName: findRestaurantInBookingList.enName,
        favoriteCount: 0,
        menuItems: 0,
        orderCount: 0,
        orderTotal: 0,
        ratingAvg: 0,
        ratingCount: 0,
        ratingTotal: 0,
        activeStatus: false,
      },
    ];
  }

  const classes = styles();

  return (
    <Box id="div-select-restaurant" sx={{ minWidth: 120, maxWidth: 400 }}>
      <FormControl fullWidth size="small">
        <Select
          id="select-restaurant"
          labelId="demo-simple-select-label"
          value={restaurant}
          defaultValue={restaurantId}
          onChange={handleChange}
          IconComponent={KeyboardArrowDownIcon}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label', id: 'select-restaurant' }}
          disabled={disabled || checkLoad}
        >
          <MenuItem id="select-restaurant-disable" disabled value="">
            <Typography className={edit ? '' : 'text-select-restaurant'}>{'Select restaurant'}</Typography>
          </MenuItem>
          {restaurantListForDropdown?.map((value: any, index) => (
            <MenuItem
              id={'select-restaurant-disable-' + index}
              className={classes.wrap_text}
              sx={{
                minWidth: 120,
                maxWidth: 400,
              }}
              value={value.id}
              key={value.id}
            >
              <Typography color={checkLoad ? 'disabled' : common.black}>
                {value.thName}({value.enName})
              </Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

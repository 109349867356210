import React, { ReactNode, useEffect, useMemo, useState } from 'react';
// mui
import { Box, Button, Container, Divider, Paper, Table, TableContainer, Tooltip, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircle from '@mui/icons-material/CheckCircle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
// components
import Modal from '@/components/ui/modal/modalOrderSummary';
import ModalCreateOrder from '@/components/ui/modal/modalCreateOrder';
import {
  ActionButton,
  BasicDatePicker,
  ConditionalWrapper,
  EnhancedTableBodyOrder,
  EnhancedTableHead,
  EnhancedTablePaginationV2,
  EnhancedTableTotalPriceOrder,
  LoadingIcon,
  PopupSnackbar,
} from '@/components/index';
// static
import { LIMIT_TIME_ORDER } from '@/app.constants/index';
import { order, orderOrderByType, QueryParamsGetList, RestaurantSchema } from '@/types/index';
import { StaffOrderSchema } from '@/types/schema/orders.schema';
import { CANCEL_ORDER_TIP_TEXT, headCellsOrder, PAGE_TITLE } from '@/static/order';
import holiday from '@/images/holiday.png';
import noOne from '@/images/noOne.png';
import theAdmin from '@/images/theAdmin.png';
// utils
import { checkSelectedDayAvailable, cookieOptions, isIpad, isIphone, printDisplayDate } from '@/utils/index';
import moment from 'moment';
// context
import { useMenuContextV2, useOrdersContextV2, useRestaurantBookingContext, useSnackbarContext } from '@/services/index';
import ModalCancelAllOrder from '@/src/components/ui/modal/modalCancelAllOrder';
import html2canvas from 'html2canvas';
import dayjs, { Dayjs } from 'dayjs';
import { useCookies } from 'react-cookie';
import { OrderInitialState } from '@/store/order.v2.store';
import TableBodyNodata from '@/src/components/ui/tableBodyNoData';
import ModalForChangeRestaurant from '@/src/components/ui/modal/modalChangeRestaurant';
import ModalConfirmChangeRestaurants from '@/src/components/ui/modal/modalConfirmChangeRestaurants';
import { BOOKING_STATUS_ENUM, STATE_ENUM } from '@/src/types/schema/enum/common.enum';

interface OrdersProps {
  [name: string]: any;
  mockupRows: any[];
  mockupStatus: any[];
  setOrders(orders: StaffOrderSchema[]): void;
  setStatus(orders: any[]): void;
}

const Orders: React.FC<any> = (props: OrdersProps) => {
  const [newRestaurant, setNewRestaurant] = React.useState<RestaurantSchema | undefined>(undefined);
  const [newRestaurantId, setNewRestaurantId] = React.useState<undefined | string>(undefined);
  const [restaurantId, setRestaurantId] = React.useState<undefined | string>(undefined);
  const { ordersSummaryList, selectedOrderDate, ordersDeleteAll } = useOrdersContextV2();
  const { menuListForOrder } = useMenuContextV2();
  const { restaurantBookingStatusUpdateData, setRestaurantBookingStatusUpdateData } = useRestaurantBookingContext();
  const { setResponseStatus } = useSnackbarContext();
  const [openAlert, setOpenAlert] = useState(false);
  // sorting/pagination
  const [rowsPerPage, setRowsPerPage] = useState(60);
  const [order, setOrder] = useState<order | null>('asc');
  const [orderBy, setOrderBy] = useState<keyof any>('');
  const [page, setPage] = useState(0);
  // state

  const [open, setOpen] = useState(false);
  const [type, setModalType] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openCreateModal, setCreateOpenModal] = useState(false);
  const [openDeleteAllOrdersModal, setOpenDeleteAllOrdersModal] = useState<boolean>(false);
  const [openConfirmChangedModal, setOpenConfirmChangedModal] = useState<boolean>(false);
  const [onResetChangedOrderRestaurants, setResetChangedOrderRestaurants] = useState<boolean>(false);
  const [isReadyForExport, setIsReadyForExport] = useState<boolean>(false);
  const [paginationParams, setPaginationParams] = useState<QueryParamsGetList<orderOrderByType>>({ page, itemPerPage: rowsPerPage });
  const [isInitialGetData, setIsInitialGetData] = useState(!ordersSummaryList.current.data?.order?.length);
  const [cookies, setCookie] = useCookies(['sortingOrders']);
  const sorting: any = cookies?.sortingOrders || {};
  const [openChangeRestaurant, setOpenChangeRestaurant] = useState(false);
  const { changedOrderRestaurants, orderDelete } = useOrdersContextV2();

  const handleChangeRestaurant = () => {
    setResetChangedOrderRestaurants(true);
    setOpenChangeRestaurant(true);
  };

  const handleCloseModalChangeRestaurant = () => {
    setOpenChangeRestaurant(false);
    setResetChangedOrderRestaurants(false);
  };

  const handleSaveChangeRestaurant = () => {
    setOpenChangeRestaurant(false);
    setOpenConfirmChangedModal(true);
  };

  const SaveChangeRestaurant = () => {
    setOpenConfirmChangedModal(false);
    if (ordersSummaryList.current.data?.date && newRestaurantId) {
      changedOrderRestaurants.requestAct(ordersSummaryList.current.data?.date, newRestaurantId);
      setOpenChangeRestaurant(false);
      setResetChangedOrderRestaurants(false);
    }
  };

  const btnCancelValidation = (): boolean => {
    const currentDateTime = new Date();
    const status = ordersSummaryList.current.data?.bookingStatus;
    if (status === BOOKING_STATUS_ENUM.CLOSED || status === BOOKING_STATUS_ENUM.ORDERED) {
      return currentDateTime > selectedOrderDate.current!;
    }
    return false;
  };

  const handleUpdate = () => {
    const date = moment(selectedOrderDate.current).format('YYYY-MM-DD');
    ordersSummaryList.requestAct(date, paginationParams);
  };

  const handleClickChange = () => {
    setModalType('MARK AS ORDERED');
    setOpenModal(true);
  };

  const handleSetBookingId = (id: any) => {
    ordersSummaryList.setState(OrderInitialState.ordersSummaryList.current);
    setPage(0);
    setPaginationParams({
      ...paginationParams,
      page: paginationParams.page === -1 ? -1 : 0,
    });
    selectedOrderDate.setState(moment(id).toDate());
  };

  const handleAddNewOrder = () => {
    setModalType('CREATE NEW ORDER');
    setCreateOpenModal(true);
  };

  const handleExportImage = () => {
    ordersSummaryList.setState({ state: 'initial' });
    setPaginationParams({ page: -1 });
    setRowsPerPage(-1);
    setIsReadyForExport(true);
  };

  const updateStatusHasError = (code: number | undefined) => {
    return !code || code >= 400 || code === 0 || false;
  };

  const checkDay = (day: string) => {
    return checkSelectedDayAvailable(day);
  };

  const openConfirmDeleteAllOrder = () => {
    setOpenDeleteAllOrdersModal(true);
  };

  const deleteAllOrder = () => {
    ordersDeleteAll.requestAct(moment(selectedOrderDate.current).format('YYYY-MM-DD'));
    setOpenDeleteAllOrdersModal(false);
  };

  const getNextLunchDay = (today: Dayjs) => {
    // if today is Friday or Saturday then next lunch day must be Monday
    if (today.day() === 6) {
      return today.add(2, 'day').format('YYYY-MM-DD');
    } else if (today.day() === 5) {
      return today.add(3, 'day').format('YYYY-MM-DD');
    } else {
      return today.add(1, 'day').format('YYYY-MM-DD');
    }
  };

  const getHolidayAndPreviousBooking = (bookingDay: Dayjs) => {
    let previousBookingDay = bookingDay.day() === 1 ? bookingDay.subtract(3, 'day') : bookingDay.subtract(1, 'day');

    const holidayData: string[] = [];
    while (
      ordersSummaryList.current.data?.holidayDates?.length &&
      ordersSummaryList.current.data?.holidayDates.find((date) => date === previousBookingDay.format('YYYY-MM-DD'))
    ) {
      holidayData.push(previousBookingDay.format('YYYY-MM-DD'));
      // Add weekend if previous booking is Friday
      if (previousBookingDay.day() === 1) {
        holidayData.push(previousBookingDay.subtract(1, 'day').format('YYYY-MM-DD'));
        holidayData.push(previousBookingDay.subtract(2, 'day').format('YYYY-MM-DD'));
      }
      // go back to previous booking day until holiday date no longer be found
      previousBookingDay = previousBookingDay.day() === 1 ? previousBookingDay.subtract(3, 'day') : previousBookingDay.subtract(1, 'day');
    }
    return {
      holidayData,
      previousBookingDay,
    };
  };

  const isWeekend = (today: Dayjs) => {
    return today.day() === 6 || today.day() === 0;
  };

  const canMarkAsOrder = () => {
    const today = dayjs();
    const todayFormatted = today.format('YYYY-MM-DD');
    const nextLunchDay = getNextLunchDay(today);

    if (ordersSummaryList.current.data?.date) {
      const bookingDay = dayjs(ordersSummaryList.current.data?.date);
      // always false for booking day in the past
      if (ordersSummaryList.current.data?.date < todayFormatted) {
        return false;
      }
      // today before 5PM
      if (ordersSummaryList.current.data?.date === todayFormatted && today.hour() < LIMIT_TIME_ORDER) {
        return true;
      }
      // holiday date found
      else if (ordersSummaryList.current.data?.holidayDates?.length && ordersSummaryList.current.data?.date > todayFormatted) {
        const holidayAndPreviousBooking = getHolidayAndPreviousBooking(bookingDay);
        // found a previous holiday
        if (holidayAndPreviousBooking.holidayData.length) {
          // check after 5PM before holiday date the next day or today is in the holiday data
          if (
            (today.hour() >= LIMIT_TIME_ORDER && todayFormatted === holidayAndPreviousBooking.previousBookingDay.format('YYYY-MM-DD')) ||
            holidayAndPreviousBooking.holidayData.find((date) => todayFormatted === date)
          ) {
            return true;
          }
        }
      }
      // No holiday date found
      // today after 5PM
      // weekend case and next lunch day is Monday
      if (
        (ordersSummaryList.current.data?.date === nextLunchDay && today.hour() >= LIMIT_TIME_ORDER) ||
        (ordersSummaryList.current.data?.date === nextLunchDay && isWeekend(today))
      ) {
        return true;
      }
    }

    return false;
  };

  const canChangedRestaurants = () => {
    const today = dayjs();
    const todayFormatted = today.format('YYYY-MM-DD');
    const orderDate = ordersSummaryList.current.data?.date;

    return orderDate && (todayFormatted < orderDate || (todayFormatted === orderDate && today.hour() < 11 && today.hour() > 0));
  };

  const downloadImage = (blob, fileName) => {
    const link = window.document.createElement('a');
    link.download = fileName;
    link.href = blob;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
  };

  const exportAsImage = async (el, imageFileName) => {
    let windowWidth = 1080;
    let scale = window.devicePixelRatio;
    // scale down if iPad is detected
    if (isIpad()) {
      windowWidth = 800;
      scale = 1;
    }
    const canvas = await html2canvas(el, {
      windowWidth,
      scale,
      onclone: function (clone) {
        // remove all elements with 'export-omit' class
        const elementsToOmit = clone.getElementsByClassName('export-omit');
        while (elementsToOmit.length > 0) {
          elementsToOmit[0].parentNode?.removeChild(elementsToOmit[0]);
        }
        // remove gutter
        const container = clone.getElementById('export');
        if (container) container.style.padding = '0px';
        // padding for title
        const title = clone.getElementsByClassName('flex-row') as HTMLCollectionOf<HTMLElement>;
        title[0].style.paddingLeft = '16px';
        const titleBox = clone.getElementById('title-box');
        if (titleBox) titleBox.style.width = '100%';
        // fix title name
        const titleName = clone.getElementById('title-name');
        if (titleName) titleName.style.overflow = 'auto';
        if (titleName) titleName.style.whiteSpace = 'normal';
        // adjust cell padding and reduce font size
        const cells = clone.getElementsByClassName('MuiTableCell-root') as HTMLCollectionOf<HTMLElement>;
        for (let i = 0; i < cells.length - 1; i++) {
          cells[i].style.padding = '4px 4px';
          cells[i].style.fontSize = '14px';
        }
        // alternate row colors
        const rows = clone.getElementsByClassName('MuiTableRow-root') as HTMLCollectionOf<HTMLElement>;
        rows[0].style.backgroundColor = '#dbdbdb';
        for (let i = 1; i < rows.length; i++) {
          if (i % 2 === 0) {
            rows[i].style.backgroundColor = '#fffae8';
          }
        }
      },
    });
    const image = canvas.toDataURL('image/png', 1.0);
    downloadImage(image, imageFileName);
  };

  useEffect(() => {
    switch (changedOrderRestaurants?.current.state) {
      case STATE_ENUM.READY:
        setResponseStatus({
          status: 'success',
          message: changedOrderRestaurants.current?.status?.message ?? '',
        });
        changedOrderRestaurants.setState({ state: STATE_ENUM.INITIAL });
        ordersSummaryList.requestAct(moment(selectedOrderDate.current).format('YYYY-MM-DD'), paginationParams);
        setOpenAlert(true);
        break;
      case STATE_ENUM.ERROR:
        setResponseStatus({
          status: changedOrderRestaurants.current.state,
          message: changedOrderRestaurants.current?.error ?? 'Something went wrong. Please try again.',
        });
        changedOrderRestaurants.setState({ state: STATE_ENUM.INITIAL });
        setOpenAlert(true);
        break;
    }
  }, [changedOrderRestaurants]);

  useEffect(() => {
    if (ordersSummaryList.current.state !== 'initial' && !isInitialGetData) {
      if (typeof paginationParams.orderBy !== 'undefined') sorting.orderBy = paginationParams.orderBy;
      if (typeof paginationParams.sort !== 'undefined') sorting.sort = paginationParams.sort || 'asc';

      setOrderBy(paginationParams.orderBy || sorting.orderBy);
      setOrder(paginationParams.sort || sorting.sort);

      paginationParams.orderBy = paginationParams.orderBy || sorting.orderBy;
      paginationParams.sort = paginationParams.sort || sorting.sort;

      setCookie('sortingOrders' as any, JSON.stringify(sorting), cookieOptions());
      ordersSummaryList.requestAct(moment(selectedOrderDate.current).format('YYYY-MM-DD'), paginationParams);
    }
  }, [paginationParams]);

  useEffect(() => {
    if (ordersDeleteAll.current.state === STATE_ENUM.READY) {
      setResponseStatus({
        status: 'success',
        message: ordersDeleteAll.current.status?.message ?? '',
      });
      setOpen(true);
      ordersSummaryList.setState((prevState) => {
        return { ...prevState, bookingStatus: 'Ordered' };
      });
      ordersDeleteAll.setState({
        state: STATE_ENUM.INITIAL,
        data: undefined,
      });
      ordersSummaryList.setState({ state: STATE_ENUM.INITIAL });
    }
    if (ordersSummaryList.current.state === STATE_ENUM.READY && !updateStatusHasError(restaurantBookingStatusUpdateData?.status?.code)) {
      // NOTE: this api only returns a status code, so we use this for now to check for success
      ordersSummaryList.setState((prevState) => ({ ...prevState, bookingStatus: 'Ordered' }));
      setRestaurantBookingStatusUpdateData({ state: STATE_ENUM.INITIAL, data: undefined });
    }
    if (ordersSummaryList.current.state === STATE_ENUM.INITIAL) {
      setIsInitialGetData(false);
      if (typeof cookies.sortingOrders !== 'undefined') {
        paginationParams.orderBy = cookies.sortingOrders?.orderBy;
        paginationParams.sort = cookies.sortingOrders?.sort;
      }

      setOrderBy(paginationParams.orderBy || orderBy);
      setOrder(paginationParams.sort || order);

      ordersSummaryList.requestAct(moment(selectedOrderDate.current).format('YYYY-MM-DD'), paginationParams);
    }
    if (ordersSummaryList.current.state === STATE_ENUM.READY && restaurantId !== ordersSummaryList.current.data?.restaurant?.restaurantId) {
      if (ordersSummaryList.current.data?.bookingStatus !== 'Holiday') {
        setRestaurantId(ordersSummaryList.current.data?.restaurant.restaurantId);
        menuListForOrder.requestAct(ordersSummaryList.current.data?.restaurant.restaurantId || '');
      }
    }
  }, [ordersSummaryList, selectedOrderDate, restaurantBookingStatusUpdateData, ordersDeleteAll]);

  // effect for generating image when full list is rendered
  useEffect(() => {
    if (isReadyForExport && ordersSummaryList.current.state === 'ready') {
      const dateString = moment(selectedOrderDate.current).format('YYYY-MM-DD');
      exportAsImage(document.getElementById('export'), `ManaoMeals_${dateString}.png`);
      setIsReadyForExport(false);
    }
  }, [isReadyForExport, ordersSummaryList.current.state]);

  const getStatusUndefined = (): ReactNode => (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ minHeight: '40vh', border: 1, borderColor: '#E0E0E0' }}>
      <Box component="img" src={theAdmin} alt="TheAdmin" sx={{ height: 49, weight: 37, marginBottom: '31px' }} />
      <Typography variant="h5" color="#9E9E9E">
        The admin had not chosen a restaurant for the day.
      </Typography>
    </Box>
  );

  const statusAddNewOrderButton: ReactNode = (
    <ConditionalWrapper
      condition={checkDay(moment(selectedOrderDate.current).format('YYYY-MM-DD')) && ordersSummaryList.current.data?.bookingStatus !== 'Closed'}
    >
      <ActionButton id="btn-add-new-order" variant="outlined" text={'ADD NEW ORDER'} startIcon={<AddIcon />} onClick={handleAddNewOrder} />
    </ConditionalWrapper>
  );

  const getStatusReadyNode = (): ReactNode => {
    if (ordersSummaryList.current.data?.bookingStatus !== 'Holiday')
      return (
        <Box className="flex flex-row justify-between items-center">
          {/* Booking info */}
          <Box id="title-box" className="w-[50%]">
            {/* Line One */}
            <Box className="flex flex-col mb-2 mt-5">
              <Tooltip title={ordersSummaryList.current.data?.restaurant?.restaurantThName} placement="bottom-start">
                <Typography id="title-name" variant="h3" className="overflow-hidden text-over text-ellipsis whitespace-nowrap" lineHeight={1.5}>
                  {ordersSummaryList.current.data?.restaurant?.restaurantThName}
                </Typography>
              </Tooltip>
              <Tooltip className="export-omit" title={ordersSummaryList.current.data?.restaurant?.restaurantEnName} placement="bottom-start">
                <Typography variant="h3" className="overflow-hidden text-over text-ellipsis whitespace-nowrap">
                  ({ordersSummaryList.current.data?.restaurant?.restaurantEnName})
                </Typography>
              </Tooltip>
            </Box>
            {/* Line Two */}
            <Box className="flex flex-row space-x-2 items-baseline">
              <Typography variant="h6">Order for {printDisplayDate(ordersSummaryList.current.data?.date || '')}</Typography>
              <Box key="{item.id1}" className="export-omit">
                {ordersSummaryList.current.data?.bookingStatus === 'Open' ? (
                  <CircleIcon sx={{ fontSize: 14 }} color="success" />
                ) : ordersSummaryList.current.data?.bookingStatus === 'Ordered' ? (
                  <CheckCircle sx={{ fontSize: 14 }} color="success" />
                ) : (
                  <CircleIcon sx={{ fontSize: 14 }} color="disabled" />
                )}
              </Box>
              <Typography className="export-omit" variant="h6">
                Status: {ordersSummaryList.current.data?.bookingStatus}
              </Typography>
            </Box>
          </Box>
          {/* Buttons */}
          <Box className="space-x-4">
            <ConditionalWrapper condition={ordersSummaryList.current.data?.order?.length !== 0} alternativeNode={statusAddNewOrderButton}>
              <ConditionalWrapper condition={ordersSummaryList.current.data?.bookingStatus !== 'Ordered'}>
                <ActionButton
                  id="btn-change-restaurant"
                  variant="outlined"
                  disabled={!canChangedRestaurants()}
                  text={'CHANGE RESTAURANT'}
                  onClick={handleChangeRestaurant}
                />
                <ActionButton
                  id="btn-mark-as-order"
                  disabled={!canMarkAsOrder()}
                  variant="contained"
                  text={'MARK AS ORDERED'}
                  startIcon={<CheckIcon />}
                  onClick={handleClickChange}
                />
                {statusAddNewOrderButton}
              </ConditionalWrapper>
              {!isIphone() && (
                <ActionButton
                  id="btn-export-list"
                  className="btn-export export-omit"
                  variant="outlined"
                  text={'EXPORT LIST'}
                  startIcon={<FileDownloadIcon />}
                  onClick={handleExportImage}
                />
              )}
              <PopupSnackbar open={openAlert} setOpen={setOpenAlert} />
            </ConditionalWrapper>
          </Box>
        </Box>
      );
    else
      return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ minHeight: '40vh', border: 1, borderColor: '#E0E0E0' }}>
          <Box component="img" src={holiday} alt="public_holiday" sx={{ height: 49, weight: 37, marginBottom: '31px' }} />
          <Typography variant="h5" color="#9E9E9E">
            The date is marked as a public holiday.
          </Typography>
        </Box>
      );
  };

  const getStatusNode = (): ReactNode => {
    switch (ordersSummaryList.current.state) {
      case 'ready':
        return getStatusReadyNode();
      case 'loading':
        return <LoadingIcon setCenter={false} />;
      case 'error':
        return getStatusUndefined();
      default:
        return <></>;
    }
  };

  const bookingId = useMemo(() => {
    return moment(selectedOrderDate.current).format('YYYY-MM-DD');
  }, [selectedOrderDate.current]);

  useEffect(() => {
    if (orderDelete?.current?.state === STATE_ENUM.READY) {
      ordersSummaryList.requestAct(bookingId, { page, itemPerPage: rowsPerPage });
      setResponseStatus({
        status: 'success',
        message: orderDelete.current.status?.message ?? '',
      });
      orderDelete.setState({
        state: STATE_ENUM.INITIAL,
        data: undefined,
      });
      setOpenModal(false);
      setOpen(true);
      setOrderBy(orderBy);
      setOrder(order);
    }
  }, [orderDelete?.current]);

  return (
    <Container maxWidth="xl" id="export">
      <Box className="pt-8 flex flex-row space-x-4 export-omit">
        <Typography variant="h1">{PAGE_TITLE}</Typography>
        <BasicDatePicker date={selectedOrderDate.current} setBookingId={handleSetBookingId} />
      </Box>
      <Box sx={{ marginTop: '28.5px', marginBottom: '24.5px' }}>
        <Divider />
      </Box>
      {getStatusNode()}

      {/* TABLE */}
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%', marginTop: '24px' }}>
        <ConditionalWrapper condition={ordersSummaryList.current.state === 'ready' && ordersSummaryList.current.data?.bookingStatus !== 'Holiday'}>
          <Paper elevation={0} sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                size="small"
                sx={{
                  minWidth: 750,
                }}
              >
                <EnhancedTableHead
                  headCells={headCellsOrder}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                  setPaginationParams={setPaginationParams}
                  disabledSorting={ordersSummaryList.current.data?.order?.length === 0}
                  respectSortAble
                />
                <ConditionalWrapper
                  condition={ordersSummaryList.current.data?.order?.length !== 0}
                  alternativeNode={<TableBodyNodata page="order" src={noOne} title="No one has placed an order yet." />}
                >
                  <EnhancedTableBodyOrder
                    rows={ordersSummaryList?.current?.data?.order}
                    order={order}
                    orderBy={orderBy}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    {...props}
                    setOpen={setOpen}
                    setOrderBy={setOrderBy}
                    setOrder={setOrder}
                    bookingId={moment(selectedOrderDate.current).format('YYYY-MM-DD')}
                  />
                  <EnhancedTableTotalPriceOrder className="export-omit" totalPrice={ordersSummaryList.current.data?.totalPrice} />
                </ConditionalWrapper>
              </Table>
              {/* Empty orders node */}
              <EnhancedTablePaginationV2
                total={ordersSummaryList.current.pagination?.total || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                setPaginationParams={setPaginationParams}
                addRowsPerPageOption={[
                  { value: 60, label: '60' },
                  { value: -1, label: 'All' },
                ]}
                disabledPagination={ordersSummaryList?.current?.data?.order?.length === 0}
              />
            </TableContainer>
            {/* Cancel all orders button */}
            <ConditionalWrapper condition={ordersSummaryList?.current?.data?.order?.length !== 0}>
              <Box className="space-x-3 flex flex-row items-center export-omit" sx={{ marginTop: '28px' }}>
                <Button
                  id="btn-cancel-all-order"
                  disabled={btnCancelValidation()}
                  onClick={openConfirmDeleteAllOrder}
                  variant="contained"
                  disableElevation
                  color="error"
                >
                  CANCEL ALL ORDERS
                </Button>
                <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{CANCEL_ORDER_TIP_TEXT}</span>} arrow placement="right">
                  <InfoIcon sx={{ fontSize: 26, color: '#9E9E9E' }} />
                </Tooltip>
              </Box>
            </ConditionalWrapper>
          </Paper>
        </ConditionalWrapper>

        {type === 'MARK AS ORDERED' && (
          <Modal
            openModal={openModal}
            type={type}
            setOpenModal={setOpenModal}
            restaurantId={restaurantId}
            bookingId={moment(selectedOrderDate.current).format('YYYY-MM-DD')}
            setModalType={setModalType}
          />
        )}
        {type === 'CREATE NEW ORDER' && (
          <ModalCreateOrder
            onUpdate={handleUpdate}
            openSnackbar={setOpen}
            openModal={openCreateModal}
            setOpenModal={setCreateOpenModal}
            bookingId={moment(selectedOrderDate.current).format('YYYY-MM-DD')}
            restaurantId={restaurantId}
          />
        )}
      </Box>
      <PopupSnackbar open={open} setOpen={setOpen}></PopupSnackbar>
      <ModalCancelAllOrder
        targetDate={printDisplayDate(ordersSummaryList?.current?.data?.date || '')}
        open={openDeleteAllOrdersModal}
        onConfirm={() => {
          deleteAllOrder();
        }}
        onCancel={() => {
          setOpenDeleteAllOrdersModal(false);
        }}
      ></ModalCancelAllOrder>
      <ModalConfirmChangeRestaurants
        open={openConfirmChangedModal}
        newRestaurant={newRestaurant}
        oldRestaurant={ordersSummaryList?.current?.data?.restaurant}
        onConfirm={() => {
          SaveChangeRestaurant();
        }}
        onCancel={() => {
          setOpenChangeRestaurant(true);
          setOpenConfirmChangedModal(false);
        }}
      ></ModalConfirmChangeRestaurants>
      <ModalForChangeRestaurant
        open={openChangeRestaurant}
        restaurantId={ordersSummaryList?.current?.data?.restaurant?.restaurantId}
        thName={ordersSummaryList?.current?.data?.restaurant?.restaurantThName}
        enName={ordersSummaryList?.current?.data?.restaurant?.restaurantEnName}
        date={ordersSummaryList?.current?.data?.date}
        status={ordersSummaryList?.current?.data?.bookingStatus}
        onClose={handleCloseModalChangeRestaurant}
        onConfirm={handleSaveChangeRestaurant}
        onChange={setNewRestaurantId}
        isResetChanged={onResetChangedOrderRestaurants}
        setSelectionRestaurant={setNewRestaurant}
      />
    </Container>
  );
};

export default Orders;

import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { apiMethodV1, apiPathV1 } from '@/src/app.constants';
// eslint-disable-next-line no-unused-vars
import { AxiosService } from '../axios';
interface PermissionWrapperProps {
  children: ReactElement | null;
  currentPage: string | ''; // Pass the current page path as a prop
}
// eslint-disable-next-line no-unused-vars
interface VerifyAccess {
  menuId: string;
}

const PermissionWrapper: React.FC<PropsWithChildren<PermissionWrapperProps>> = ({ children, currentPage }) => {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
  useEffect(() => {
    const checkPermission = async () => {
      const instance = new AxiosService({ method: apiMethodV1.post, timeout: 12000 }, true, true);

      const config = {
        ...instance.config,
      };
      const body = {
        menuId: currentPage,
      } as VerifyAccess;

      let response = 0;
      try {
        response = (await instance.client.post(apiPathV1.authVerifyAccess, JSON.stringify(body), config)).status;
      } catch (error: any) {
        response = 403;
      }
      if (response === 200) {
        setIsAuthorized(true); // User is authorized
      } else if (response === 403) {
        setIsAuthorized(false);
        navigate('/access-denied');
      }
      setIsAuthorized(true);
    };
    checkPermission();
  }, [currentPage, navigate]);

  if (isAuthorized === null || isAuthorized === false) {
    return null;
  } else {
    return children;
  }
};

export default PermissionWrapper;

import React, { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react';
import TableBody from '@mui/material/TableBody';
import { TextField, Tooltip, Button, Stack, TableCell, TableRow, Radio } from '@mui/material';
import Modal from '../modal/modalMenu';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { RestaurantMenuSchema } from '@/types/schema/restaurantMenu';
import {
  QueryMenuListSortBy,
  order,
  RestaurantAddOnsSchema,
  RestaurantGroupsSchema,
  RestaurantMenuBody,
  MenuOverviewList,
  QueryParamsGetList,
  AddonId,
  sortType,
} from '@/types/index';
import SelectAddOns from '../selectAddOns';
import SelectOptionGroups from '../selectOptionGroups';
import { useParams } from 'react-router-dom';
import { useMenuContextV2, useSnackbarContext, initialMenuState, useRestaurantOptionAddonContextV2 } from '@/services/index';
import { LIMIT_EN_CHAR, LIMIT_TH_CHAR } from '@/app.constants/index';
import ModalCancelOrder from '../modal/modalCancelOrder';
import { PriceInput } from '../sharedPriceInputComponent';
import { RemoveImageArea } from '../removeImageArea';
import { ImageUpload } from '../imageUpload';
import { STATE_ENUM } from '@/src/types/schema/enum/common.enum';
import { updateFallbackMealSchema } from '@/src/types/schema/updateFallbackMeal';
import ModalForChangeMenuFallbackMeal from '../modal/modalChangeFallbackMeal';
import { OptionAddonOrderByType } from '@/src/types/crud/optionAddon/optionAddon.queries';

interface EnhancedTableBodyRestaurantMenuProps {
  rows: MenuOverviewList[];
  order: order | null;
  orderBy: QueryMenuListSortBy | null;
  setOrderBy: Dispatch<SetStateAction<QueryMenuListSortBy | null>>;
  setOrder: Dispatch<SetStateAction<order | null>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setTotal: Dispatch<SetStateAction<number>>;
  setIsChange: Dispatch<SetStateAction<boolean>>;
  total: number;
  dropdownOptionGroupsData: { label: string; value: string }[];
  dropdownAddOnsData: { label: string; value: string }[];
  setPaginationParams: React.Dispatch<React.SetStateAction<QueryParamsGetList<any>>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}
export type CurrentImageDataType = {
  preUploadFile: any;
  imgErrorText: string;
  previousImage: any;
  isUpdateImage: boolean;
  imageBase64: string;
};

export function EnhancedTableBodyRestaurantMenu(props: EnhancedTableBodyRestaurantMenuProps) {
  const {
    rows,
    order,
    orderBy,
    setOrderBy,
    setOrder,
    setOpen,
    setTotal,
    setIsChange,
    total,
    dropdownOptionGroupsData,
    dropdownAddOnsData,
    setPaginationParams,
    setPage,
  } = props;

  const [type, setType] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const { optionAddonList, refreshOptionAddonList } = useRestaurantOptionAddonContextV2();
  const [thaiText, setThaiText] = useState('');
  const [engText, setEngText] = useState('');
  const [priceText, setPrice] = useState('');
  const countThaiText = useRef(0);
  const countEngText = useRef(0);
  const countPrice = useRef(0);
  const thaiChar = LIMIT_TH_CHAR;
  const engChar = LIMIT_EN_CHAR;
  const [thNameErrorText, setThNameErrorText] = useState('');
  const [enNameErrorText, setEnNameErrorText] = useState('');
  const [priceErrorText, setPriceErrorText] = useState('');
  const initImageData: CurrentImageDataType = {
    preUploadFile: [],
    imgErrorText: '',
    previousImage: {},
    isUpdateImage: false,
    imageBase64: '',
  };
  const [currentImageData, setCurrentImageData] = useState(initImageData);
  const [prevMenu, setPrevMenu] = useState({
    prevThai: '',
    prevEng: '',
    prevPrice: '',
    prevAddon: [''],
    prevOptionGroup: [],
  });
  const [selectAddonId, setSelectAddonId] = useState<Array<string>>([]);
  const [selectOptionGroupId, setSelectOptionGroupId] = useState<Array<string>>([]);
  const [checkDup, setCheckDup] = useState(false);
  const [validate, setValidate] = useState(false);
  const [checkLoad, setCheckLoad] = useState(false);
  const { setResponseStatus } = useSnackbarContext();
  const [restaurantMenuId, setRestaurantMenuId] = useState('');
  const [restaurantId, setRestaurantId] = useState<undefined | string>(undefined);
  const { id } = useParams();
  const [editRowIndex, setEditRowIndex] = useState<undefined | number>(-1);
  const { menuList, menuUpdate, menuDelete, overBudgetOrdersByMenu, fallbackMealChange, updateMenuListByIdUpdated, updateMenuEditing } = useMenuContextV2();

  // state for modal confirmation when the new price affects orders to go over budget.
  const [openCancelOrdersModal, setOpenCancelOrdersModal] = useState<boolean>(false);
  // state for the waiting response from over-budget check, use to prevent more action while checking.
  const [isCheckingOverBudget, setCheckingOverBudget] = useState<boolean>(false);
  // menu row that currently check price and get over budget orders.
  const [currentCheckingMenu, setCurrentCheckingMenu] = useState<RestaurantMenuSchema | undefined>();
  // number of overBudget Orders that response from API.
  const [overBudgetOrdersCount, setOverBudgetOrdersCount] = useState<number>(0);

  const [openChangeFallback, setOpenChangeFallback] = useState(false);
  const [selectedRow, setSelectedRow] = useState<MenuOverviewList | null>(null);
  const handleCloseModalChangeFallback = () => setOpenChangeFallback(false);
  const label = { inputProps: { 'aria-label': 'Radio fallbackMeal' } };
  const [fallbackIsExist, setFallbackIsExist] = useState<RestaurantMenuSchema | undefined>(undefined);

  const handleOpenDelete = (menuId: string, restaurantId: undefined | string) => {
    setType('DELETE');
    setOpenModal(true);
    setRestaurantId(restaurantId);
    setRestaurantMenuId(menuId);
  };

  const handleCancel = () => {
    setEditRowIndex(-1);
    setThNameErrorText('');
    setEnNameErrorText('');
    setPriceErrorText('');
    setCurrentImageData(initImageData);
    /** Set state to INITIAL after cancel edit */
    menuUpdate.setState(initialMenuState.menuUpdate.current);
  };

  function handleEditClick(index: number, thName: string, enName: string, price: string, prevAddon: any, prevOptionGroup: any, image: any) {
    const prevAddonIdArray: Array<string> = [];
    for (let i = 0; i < prevAddon.length; i++) {
      const found: string = prevAddon[i].id;
      prevAddonIdArray.push(found);
    }

    const prevOptionGroupIdArray: Array<string> = [];
    for (let i = 0; i < prevOptionGroup.length; i++) {
      const found: string = prevOptionGroup[i].id;
      prevOptionGroupIdArray.push(found);
    }
    setSelectAddonId([]);
    setSelectOptionGroupId([]);
    setThNameErrorText('');
    setEnNameErrorText('');
    setPriceErrorText('');
    setThaiText(thName);
    setEngText(enName);
    setPrice(price);
    setPrevMenu({
      prevThai: thName,
      prevEng: enName,
      prevPrice: price,
      prevAddon: prevAddonIdArray,
      prevOptionGroup: prevOptionGroupIdArray as [],
    });
    setCurrentImageData({ ...initImageData, previousImage: image });
    setSelectAddonId(prevAddonIdArray);
    setSelectOptionGroupId(prevOptionGroupIdArray);
    setEditRowIndex(index);
    countEngText.current = enName.trim().length;
    countThaiText.current = thName.trim().length;
    countPrice.current = price.trim().length;

    /** Set state edit when user click edit button */
    updateMenuEditing();
  }

  const handleCheckPriceBeforeSave = (menuRow: RestaurantMenuSchema) => {
    if (id) {
      setCheckingOverBudget(true);
      setCheckLoad(true);
      setCurrentCheckingMenu(menuRow);
      overBudgetOrdersByMenu.requestAct(id, menuRow.id, { price: parseInt(priceText) });
    }
  };

  const handleClickSave = (row: RestaurantMenuSchema) => {
    const payload = {
      thName: thaiText?.trim(),
      enName: engText?.trim(),
      price: parseInt(priceText),
      addonId: selectAddonId,
      groupId: selectOptionGroupId,
      oldImage: currentImageData.previousImage?.id,
      newImage: currentImageData.isUpdateImage ? currentImageData.imageBase64 : currentImageData.previousImage?.id,
    } as RestaurantMenuBody;
    setRestaurantMenuId(row.id);
    menuUpdate.requestAct(id || '', row.id, payload);
    setValidate(false);
  };

  useEffect(() => {
    if (overBudgetOrdersByMenu.current.state === 'ready' && isCheckingOverBudget && currentCheckingMenu) {
      // NOTE: if get the response success then check if there is the overBudget Orders or not.
      if (overBudgetOrdersByMenu.current.data?.amount === 0) {
        handleClickSave(currentCheckingMenu);
        setCurrentCheckingMenu(undefined);
      } else {
        if (overBudgetOrdersByMenu.current.data?.amount) setOverBudgetOrdersCount(overBudgetOrdersByMenu.current.data?.amount);
        setOpenCancelOrdersModal(true);
      }
      setCheckingOverBudget(false);
    }
  }, [overBudgetOrdersByMenu.current]);

  useEffect(() => {
    if (menuUpdate.current.state === 'ready') {
      setResponseStatus({
        status: 'success',
        message: menuUpdate.current.status?.message ?? '',
      });
      menuUpdate.setState(initialMenuState.menuUpdate.current);
      setOpen(true);
      setEditRowIndex(-1);
      setOrderBy(orderBy);
      setOrder(order);
      setCheckLoad(true);
      const updatedMenu = (menuUpdate.current.data as RestaurantMenuSchema[])[0];
      if (currentImageData.isUpdateImage) {
        updatedMenu.image.thumbnailLink = currentImageData.preUploadFile[0]?.dataURL;
      }

      updateMenuListByIdUpdated(updatedMenu.id, updatedMenu as MenuOverviewList);
      setCurrentImageData(initImageData);
    } else if (menuUpdate.current.state === 'error') {
      setResponseStatus({
        status: menuUpdate.current.state,
        message: menuUpdate.current?.status?.message ?? '',
      });
      menuUpdate.setState(initialMenuState.menuUpdate.current);
      setOpen(true);
      setCheckLoad(true);
    }
    if (editRowIndex === -1) {
      setCheckLoad(false);
    }
  }, [menuUpdate.current]);

  useEffect(() => {
    if (menuDelete.current.state === STATE_ENUM.READY) {
      setIsChange(true);
      setTotal(total - 1);
      if (menuList.current.pagination?.total !== undefined) {
        menuList.current.pagination.total--;
      }

      if (menuList.current.data?.menuAddonOverviewList.length === 1 && menuList.current.pagination?.page! > 0) {
        setPage(menuList.current.pagination?.page! - 1);
        setPaginationParams((prevState) => {
          return { ...prevState, page: menuList.current.pagination?.page! - 1 };
        });
      } else {
        setPage(menuList.current.pagination?.page!);
        setPaginationParams((prevState) => {
          return { ...prevState, page: menuList.current.pagination?.page! };
        });
      }

      setResponseStatus({
        status: 'success',
        message: menuDelete.current?.status?.message ?? '',
      });
      menuDelete.setState(initialMenuState.menuDelete.current);
      setOpen(true);
      setOrderBy(orderBy);
      setOrder(order);
    } else if (menuDelete.current.state === STATE_ENUM.ERROR) {
      setResponseStatus({
        status: menuDelete.current.state,
        message: fallbackMealChange.current?.error ?? 'Something went wrong. Please try again.',
      });
    }
  }, [menuDelete.current]);

  const checkDuplicateThai = () => {
    const tempArray = menuList.current.data?.menuAddonOverviewList || [];
    return tempArray.find((item) => item.thName === thaiText?.trim() && prevMenu.prevThai !== thaiText?.trim());
  };

  const checkDuplicateEng = () => {
    const tempArray = menuList.current.data?.menuAddonOverviewList || [];
    return tempArray.find((item) => item.enName.toUpperCase() === engText?.trim().toUpperCase() && prevMenu.prevEng !== engText?.trim());
  };

  const handleThaiNameTextChange = (event: any, index: number) => {
    countThaiText.current = event.target.value.trim().length;
    setEditRowIndex(index);
    setThaiText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(thaiChar)) {
      setThNameErrorText('Thai name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setThNameErrorText('Thai name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setThNameErrorText('Max 100 characters.');
    } else if (event.target.value.trim() === checkDuplicateThai()?.thName) {
      setThNameErrorText('This add-on already exists in this restaurant.');
    } else {
      if (enNameErrorText === '' && priceErrorText === '') {
        setValidate(true);
      }
      setThNameErrorText('');
    }
  };

  const handleEngNameTextChange = (event: any, index: number) => {
    countEngText.current = event.target.value.trim().length;
    setEditRowIndex(index);
    setEngText(event.target.value);
    if (event.target.value.length === 0 || !event.target.value.match(engChar)) {
      setEnNameErrorText('English name is required.');
      setValidate(false);
    } else if (event.target.value.trim().length === 0) {
      setValidate(false);
      setEnNameErrorText('English name is required.');
    } else if (event.target.value.length > 100) {
      setValidate(false);
      setEnNameErrorText('Max 100 characters.');
    } else if (event.target.value.trim() === checkDuplicateEng()?.enName) {
      setEnNameErrorText('This add-on already exists in this restaurant.');
    } else {
      if (thNameErrorText === '' && priceErrorText === '') {
        setValidate(true);
      }
      setEnNameErrorText('');
    }
  };

  const handleOutOfFocusThaiName = () => {
    if (countThaiText.current === 0) {
      setThaiText('');
    }
  };

  const handleOutOfFocusEngName = () => {
    if (countEngText.current === 0) {
      setEngText('');
    }
  };

  useEffect(() => {
    if (
      prevMenu.prevThai === thaiText &&
      prevMenu.prevEng === engText &&
      prevMenu.prevPrice === priceText &&
      JSON.stringify(prevMenu.prevAddon.sort()) === JSON.stringify(selectAddonId.sort()) &&
      JSON.stringify(prevMenu.prevOptionGroup.sort()) === JSON.stringify(selectOptionGroupId.sort()) &&
      !currentImageData.isUpdateImage
    ) {
      setCheckDup(false);
    } else if (checkDuplicateThai() === undefined && checkDuplicateEng() === undefined) {
      setCheckDup(true);
    } else {
      if (thaiText.trim() === checkDuplicateThai()?.thName) {
        setThNameErrorText('This menu item already exists in this restaurant.');
        setCheckDup(false);
      }
      if (engText?.trim().toUpperCase() === checkDuplicateEng()?.enName.toUpperCase()) {
        setEnNameErrorText('This menu item already exists in this restaurant.');
        setCheckDup(false);
        if (checkDuplicateEng()?.enName === prevMenu.prevEng) {
          setEnNameErrorText('');
          setCheckDup(true);
        }
      }
    }
    if (
      countEngText.current !== 0 &&
      countThaiText.current !== 0 &&
      countPrice.current !== 0 &&
      thNameErrorText === '' &&
      enNameErrorText === '' &&
      priceErrorText === ''
    ) {
      setValidate(true);
    } else {
      setValidate(false);
    }
  }, [thaiText, engText, priceText, selectAddonId, prevMenu.prevAddon, selectOptionGroupId, prevMenu.prevOptionGroup, currentImageData]);

  useEffect(() => {
    return () => {
      optionAddonList.setState({
        data: {
          restaurant: {
            thName: '',
            enName: '',
            id: '',
          },
          group: { id: '', thName: '', enName: '', groupRadio: false },
          addon: [],
        },
        state: STATE_ENUM.INITIAL,
      });
    };
  }, []);

  useEffect(() => {
    switch (fallbackMealChange?.current.state) {
      case STATE_ENUM.READY:
        if (id) {
          if (fallbackMealChange.current.state === STATE_ENUM.READY && fallbackMealChange.current.status?.code === 200) {
            rows?.forEach((t) => {
              t.fallbackMeal = t.id === selectedRow?.id;
            });
            setOpen(true);
          }
        }
        fallbackMealChange.setState({ state: STATE_ENUM.INITIAL });
        break;
      case STATE_ENUM.ERROR:
        setResponseStatus({
          status: fallbackMealChange.current.state,
          message: fallbackMealChange.current?.status?.message ?? 'Something went wrong. Please try again.',
        });
        fallbackMealChange.setState({ state: STATE_ENUM.INITIAL });
        setOpen(true);
        break;
    }
  }, [fallbackMealChange]);

  const sendFallbackMealRequest = (menuId: string, addons: AddonId[], restaurantId: string) => {
    const payload: updateFallbackMealSchema = {
      menuId,
      addonId: addons,
      restaurantId,
    };
    fallbackMealChange.requestAct(payload);
  };

  const buildChangeFallBackPayload = async (row: MenuOverviewList) => {
    if (row && id) {
      setRestaurantMenuId(row.id);
      const addonId: AddonId[] = [];
      if (refreshOptionAddonList.current.state === STATE_ENUM.READY) {
        const addon = refreshOptionAddonList?.current?.data?.addon;
        const group = refreshOptionAddonList?.current?.data?.group;

        if (addon?.length) {
          addonId.push({ type: 'optionAddon', id: addon[0].id, groupId: group?.id });
        }
        refreshOptionAddonList.setState({ state: STATE_ENUM.INITIAL });
      }
      sendFallbackMealRequest(row.id, addonId, id);
    }
  };

  const handleRequestFallBackMealRequest = (row: MenuOverviewList) => {
    const exists = rows?.find((item) => item.fallbackMeal) || menuList?.current?.data?.fallBackMenu;
    setFallbackIsExist(exists);
    if (exists) {
      setOpenChangeFallback(true);
    } else {
      buildChangeFallBackPayload(row);
    }
  };

  useEffect(() => {
    if (refreshOptionAddonList.current.state === STATE_ENUM.READY && selectedRow) {
      handleRequestFallBackMealRequest(selectedRow);
    } else if (refreshOptionAddonList.current.state === STATE_ENUM.ERROR) {
      refreshOptionAddonList.setState({ state: STATE_ENUM.INITIAL });
      setOpen(true);
    }
  }, [refreshOptionAddonList]);

  const handleConfirmFallbackChange = () => {
    if (selectedRow && id) {
      buildChangeFallBackPayload(selectedRow);
      handleCloseModalChangeFallback();
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, row: MenuOverviewList) => {
    if (event.target.checked && id && row) {
      setSelectedRow(row);

      const { group } = row;
      if (!group) {
        handleRequestFallBackMealRequest(row);
        return;
      }
      const item = group.find((t) => t.groupRadio);
      if (!item) {
        handleRequestFallBackMealRequest(row);
        return;
      }

      const params = {
        page: 0,
        itemPerPage: 10,
        orderBy: 'thName' as OptionAddonOrderByType,
        sort: 'asc' as sortType,
      };

      refreshOptionAddonList.requestAct(id, item.id, params);
    }
  };

  return (
    <TableBody>
      {rows?.map((row, index) => (
        <TableRow
          id={'tr-restaurant-menu-' + index}
          hover
          tabIndex={index}
          key={`${row.thName}+ ${index}`}
          sx={{ width: 20 }}
          className={`editTableData ${editRowIndex === index ? 'active' : null}`}
        >
          <TableCell align="left" className="editInput" id={'td-edit-restaurant-menu-col1-' + index}>
            <TextField
              className={`${openCancelOrdersModal ? 'active' : 'not-active'}`}
              value={editRowIndex === index ? thaiText : row.thName}
              error={editRowIndex === index && thNameErrorText !== ''}
              onChange={(e) => {
                handleThaiNameTextChange(e, index);
              }}
              onBlur={(e) => {
                handleOutOfFocusThaiName();
              }}
              helperText={editRowIndex === index && thNameErrorText !== '' ? thNameErrorText : ''}
              tabIndex={-1}
              inputProps={{ maxLength: 101 }}
              disabled={checkLoad}
              id={'edit-th-name'}
            />
          </TableCell>
          <TableCell align="left" className="editInput" id={'td-edit-restaurant-menu-col2-' + index}>
            <TextField
              className="w-full"
              value={editRowIndex === index ? engText : row.enName}
              error={editRowIndex === index && enNameErrorText !== ''}
              onChange={(e) => {
                handleEngNameTextChange(e, index);
              }}
              onBlur={(e) => {
                handleOutOfFocusEngName();
              }}
              helperText={editRowIndex === index && enNameErrorText !== '' ? enNameErrorText : ''}
              inputProps={{ maxLength: 101 }}
              disabled={checkLoad}
              id={'edit-en-name'}
            />
          </TableCell>
          <TableCell align="left" className="editInput" id={'td-edit-restaurant-menu-col2-' + index}>
            <PriceInput
              price={priceText}
              priceErrorText={priceErrorText}
              setPrice={setPrice}
              setPriceErrorText={setPriceErrorText}
              setValidate={setValidate}
              countPrice={countPrice}
              editRowSetup={{
                rowIndex: index,
                rowPrice: row.price,
                editRowIndex,
                setEditRowIndex,
              }}
              disabled={checkLoad}
            />
          </TableCell>
          <TableCell align="left" className="editInput" id={'td-edit-restaurant-menu-col3-' + index}>
            <SelectAddOns
              selectedAddOns={row.addon || ([] as RestaurantAddOnsSchema[])}
              setSelectAddonId={setSelectAddonId}
              editRowIndex={editRowIndex}
              index={index}
              dropdownAddOnsData={
                dropdownAddOnsData.length === 0 && row.addon && row.addon?.length !== 0
                  ? row.addon.map((item) => {
                      return { label: item.enName, value: item.id };
                    })
                  : dropdownAddOnsData
              }
              disabled={checkLoad}
              inputId={'select-addon-input'}
            />
          </TableCell>
          <TableCell align="left" className="editInput" id={'td-edit-restaurant-menu-col4-' + index}>
            <SelectOptionGroups
              disable={isCheckingOverBudget || checkLoad}
              selectedOptionGroups={row.group || ([] as RestaurantGroupsSchema[])}
              setSelectOptionGroupId={setSelectOptionGroupId}
              editRowIndex={editRowIndex}
              index={index}
              dropdownOptionGroupsData={
                dropdownOptionGroupsData.length === 0 && row.group && row.group?.length !== 0
                  ? row.group.map((item) => {
                      return { label: item.enName, value: item.id };
                    })
                  : dropdownOptionGroupsData
              }
              inputId={'select-option-input'}
            />
          </TableCell>

          <TableCell align="center" className="btnActionArea" id={'td-edit-restaurant-menu-col5-' + index}>
            {!row.image?.thumbnailLink && editRowIndex !== index ? (
              <></>
            ) : row.image?.thumbnailLink && (editRowIndex !== index || (editRowIndex === index && !currentImageData.isUpdateImage)) ? (
              <RemoveImageArea
                key={index}
                id={`menu-${row.id}`}
                imageURL={row.image?.thumbnailLink}
                onClick={() => {
                  setCurrentImageData({
                    ...currentImageData,
                    imgErrorText: '',
                    isUpdateImage: true,
                  });
                }}
                layout={{
                  width: 40,
                  height: 40,
                }}
              />
            ) : (
              <ImageUpload
                key={index}
                id={`menu-${row.id}`}
                currentImageData={currentImageData}
                setCurrentImageData={setCurrentImageData}
                layout={{ width: 40, height: 40 }}
                useBuiltInCallError
                disabled={checkLoad}
              />
            )}
          </TableCell>
          <TableCell key={index} align="center">
            <Radio
              {...label}
              checked={row.fallbackMeal}
              onChange={(event) => handleRadioChange(event, row)}
              value={row.fallbackMeal}
              name="radio-buttons"
              sx={{
                '&.Mui-checked': {
                  color: '#4caf50',
                },
              }}
              disabled={row.fallbackMeal}
            />
          </TableCell>
          <TableCell align="center" className="btnActionArea" id={'td-edit-restaurant-menu-col6-' + index}>
            <Stack direction="row" spacing={2} className="btnArea ">
              <Button
                id="btn-save-restaurant-menu"
                variant="contained"
                color="primary"
                size="small"
                className="w-20"
                disabled={!validate || !checkDup || isCheckingOverBudget || checkLoad}
                onClick={() => handleCheckPriceBeforeSave(row)}
              >
                Save
              </Button>
              <Button
                id="btn-cancel-restaurant-menu"
                variant="outlined"
                color="primary"
                size="small"
                className="w-20"
                onClick={() => handleCancel()}
                disabled={isCheckingOverBudget || checkLoad}
              >
                Cancel
              </Button>
            </Stack>
            <Stack direction="row" spacing={2} className="btnAreaAction">
              <Tooltip title="Edit info" placement="bottom-start">
                <Button
                  id={'btn-edit-restaurant-menu' + index}
                  variant="outlined"
                  color="primary"
                  size="small"
                  className="whenHoverPointer"
                  onClick={() => handleEditClick(index, row.thName, row.enName, row.price.toString(), row.addon, row.group, row.image)}
                  disabled={isCheckingOverBudget || checkLoad}
                >
                  <EditIcon></EditIcon>
                </Button>
              </Tooltip>
              <Tooltip
                title={
                  row.fallbackMeal
                    ? 'Unable to delete: This item is currently set as the fallback meal for this restaurant. Please choose another menu item as the fallback meal before deleting.'
                    : 'Delete'
                }
                placement="bottom-start"
              >
                <span>
                  <Button
                    id={'btn-delete-restaurant-menu' + index}
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => handleOpenDelete(row.id, id)}
                    disabled={row.fallbackMeal || isCheckingOverBudget || checkLoad}
                  >
                    <DeleteIcon />
                  </Button>
                </span>
              </Tooltip>
            </Stack>
          </TableCell>
        </TableRow>
      ))}
      {/* NOTE: this TableRow is hidden, just for allow table to place modal inside */}
      <TableRow id="tr-edit-restaurant-menu-modal">
        <TableCell sx={{ padding: '0px', border: 'none' }} id={'td-edit-restaurant-menu-modal-col1'}>
          <Modal
            openModal={openModal}
            type={type}
            setOpenModal={setOpenModal}
            restaurantId={restaurantId || ''}
            restaurantMenuId={restaurantMenuId}
            setRestaurantMenuDelete={menuDelete.requestAct}
            restaurantMenuDeleteData={menuDelete.current}
            setRestaurantMenuDeleteData={menuDelete.setState}
            setType={setType}
          ></Modal>

          <ModalCancelOrder
            open={openCancelOrdersModal}
            overBudgetOrdersCount={overBudgetOrdersCount}
            onConfirm={() => {
              if (currentCheckingMenu) handleClickSave(currentCheckingMenu);
              setCurrentCheckingMenu(undefined);
              setOverBudgetOrdersCount(0);
              setOpenCancelOrdersModal(false);
            }}
            onCancel={() => {
              setOverBudgetOrdersCount(0);
              setOpenCancelOrdersModal(false);
              setCheckLoad(false);
            }}
          />
          <ModalForChangeMenuFallbackMeal
            open={openChangeFallback}
            handleClose={handleCloseModalChangeFallback}
            handleConfirm={handleConfirmFallbackChange}
            newMeal={`${selectedRow?.thName || ''} (${selectedRow?.enName || ''})`}
            oldMeal={`${fallbackIsExist?.thName || ''} (${fallbackIsExist?.enName || ''})`}
          />
        </TableCell>
      </TableRow>
    </TableBody>
  );
}
